import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

const AboutPage = () => (
    <Layout>
        <Seo title="About"/>
        <p>Howdy and welcome to Kickass Presbyterian. I'm Matthew Johnston, and I created this
            page as a way to share my thoughts.</p>
        <h2>How KP Started</h2>
        <p>This website was started kind of as a joke, after I told my dad (a pastor) that he should name a church
            "Kickass Presbyterian".</p>
        <p>A little while after that conversation, I purchased the domain, but didn't really do anything with it.
            My dad suggested I could turn it into my own corner of the internet to share the thoughts that I have.</p>
        <p>In my personal life, I'm constantly discussing politics and culture with others, and now I have a place
            where I can do that on the internet too.</p>
        <h2>My Beliefs</h2>
        <p>
            I affirm the beliefs laid out in the Apostle's Creed:
        </p>
        <blockquote>
            I believe in God the Father Almighty, Maker of heaven and earth.<br/><br/>

            I believe in Jesus Christ, His only Son, our Lord,<br/>
            who was conceived by the Holy Spirit,<br/>
            and born of the virgin Mary.<br/>
            He suffered under Pontius Pilate,<br/>
            was crucified, died, and was buried;<br/>
            he descended into hell.<br/>
            The third day he rose again from the dead.<br/>
            He ascended into heaven<br/>
            and is seated at the right hand of God the Father Almighty.<br/>
            From there He will come to judge the living and the dead.<br/><br/>

            I believe in the Holy Spirit,<br/>
            the holy catholic church,<br/>
            the communion of saints,<br/>
            the forgiveness of sins,<br/>
            the resurrection of the body,<br/>
            and the life everlasting. Amen.
        </blockquote>
    </Layout>
);

export default AboutPage;
